/*
 * Icons
 */

.badge-unconfirmed,
.tooltip_circle {
  .badge();
  background: @brand-warning;
  padding-bottom: 4px;
}

.badge-portfolio {
  .badge();
}

.badge-lg {
  font-size: inherit !important;
}

.dropdown {
  a.badge-portfolio,
  a.badge {
    color: @badge-color;
    background: @badge-bg;

    &:hover,
    &:focus,
    &.hover,
    &:active,
    &.active {
      text-decoration: none !important;
    }
    .caret {
      border-top-color: @badge-color;
    }
  }
}

.tooltip-inner {
  /* break long tooltips in multible lines */
  white-space: normal;
}

/*
 * Font Awesome overwrites
 */
a.icon_with_tooltip {
  color: inherit !important;
  text-decoration: none;
}
.@{fa-css-prefix}-portfolio:before { content: @fa-var-folder-open; }
.@{fa-css-prefix}-holding:before { content: @fa-var-file-text; }
.@{fa-css-prefix}-tooltip:before { content: @fa-var-question-circle; }
.@{fa-css-prefix}-delete:before { content: @fa-var-trash-o; }
.@{fa-css-prefix}-attachment:before { content: @fa-var-paperclip; }
.@{fa-css-prefix}-updated:before { content: @fa-var-lightbulb-o; }
.@{fa-css-prefix}-imported:before { content: @fa-var-wrench; }

.@{fa-css-prefix}-spinner {
  .make-spinning();
  &:before {
    content: @fa-var-spinner;
  }
}
.@{fa-css-prefix}-comments:before { content: @fa-var-comment-o; }
.@{fa-css-prefix}-draggable:before {
  content: @fa-var-ellipsis-h;
  font-size: 1.5em;
}
.@{fa-css-prefix}-back:before { content: @fa-var-chevron-circle-left; }
.@{fa-css-prefix}-include-sold-shares:before { content: @fa-var-tachometer; }

// company event icons
.@{fa-css-prefix}-ce-rejected:before { content: @fa-var-ban; }
.@{fa-css-prefix}-ce-confirmed:before { content: @fa-var-check-square-o; }
.@{fa-css-prefix}-unconfirmed:before,
.@{fa-css-prefix}-ce-unconfirmed:before { content: @fa-var-bolt; }
.@{fa-css-prefix}-ce-archive:before { content: @fa-var-archive; }
.@{fa-css-prefix}-ce-info:before { content: @fa-var-info-circle; }


/*
 * Special tooltips and popovers
 */
.graph-tooltips {
  > a,
  .ico-tooltip {
    color: @gray !important;
  }
}

.exclamation-mark-container {
  position: relative;
  span {
    background-color: #feead4;
    border-color: #fcd9b2;
    border-radius: 15px;
    color: #9b5305;
    font-size: 1.2ex;
    font-weight: bold;
    line-height: 2ex;
    margin: 2px;
    padding: 3px;
    position: absolute;
    top: -1px;
    width: 3ex;
  }
}
