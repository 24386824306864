.submit-row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.reveal-password i {
  cursor: pointer;
}

.broker-import-password::after {
  position: absolute;
  top: 0px;
  right: 0;
  height: 100%;
  display: block;
  border-left: 1px solid #9dc3d0;
  border-right: 1px solid #e1edf1;
  content: '';
}

.importers .broker-import-password::after {
  display: none;
}
