.professional-search {
  .professional-manage-title {
    text-align: center;
    display: block;
    margin-bottom: @gutter;

    @media (min-width: @screen-tablet) {
      margin: 0 30px 0 0;
      float: left;
      vertical-align: middle;
      line-height: 42px; // match input's full height
    }
  }

  .input-group-addon {
    border-radius: 6px 0 0 6px !important;
    border-right: none;
    line-height: 28px;
  }

  input {
    border-radius: 0 6px 6px 0 !important;
    border: 1px solid #cccccc;
    width: 50%;
    height: 42px;
    line-height: 28px;

    @media (max-width: @screen-tablet) {
      width: 100%; // this is a display: table-cell; so let's be a cell! (width: auto; overflows)
    }

    // remove the little x on the right of the input field (IE only)
    &[type=text]::-ms-clear {
      display: none;
    }
  }

  .btn-group.pull-right {
    @media (max-width: @screen-tablet) {
      display: table-cell;
      float: none !important; // because .pull-right does float: right !important;
      padding-left: @gutter;

      // as it's a table cell, the children will still fill and calculate a new width; this just does "minimum size possible"
      width: 1px;
      overflow: visible;
    }
  }

  a.btn {
    line-height: 28px; // match input
  }
}
