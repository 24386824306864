#holding_comments_attachments,
.attachments {
  .no-attachments {
    text-align: center;
    padding: @gutter (@gutter/2);
    margin-bottom: -@gutter;

    &.left-style {
      text-align: left;
      img {
        float: left;
        margin-right: (@gutter/2);
      }

      p {
        margin-top: @gutter;
      }
    }
  }
} // end #holding_comments_attachments

#holding_attachments_actions,
.trade_attachments_actions {
  #dropzone {
    display: none;
    overflow: hidden;
    background: rgba(0,0,0,0.3);

    section {
      width: 280px;
      background: rgba(0,0,0,0.5);
      border-radius: 10px;
      margin: 15% auto 0;
      padding: 30px;

      h1,h2,h3 {
        margin: 0;
        font-size: 3em;
        color: white !important;
        text-shadow: 0 1px 2px @gray-darker;
      }
    }

    &.hover {
      display: block;
      height: auto;
    }

    &.in {
      display: block;
      height: auto;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: @zindex-drag-and-drop;
    }
  }


  .fileuploader {
    overflow: hidden;
  }



  .fileuploader {
    overflow: hidden;
    height: 33px; /* as bootstrap btn */

    input.multifile,
    input.singlefile {
      opacity: 0;
      position: relative;
      text-indent: -9999px;
      top: -32px;
      height: 32px; /* as bootstrap btn */
      left: -0;
      width: 100%;
      cursor: pointer;
    }
  }
} // end #holding_attachments_actions

ul#attachments {
  //.list-unstyled();
  //background: yellow!important;
  .nav();
  .nav-pills();
  .nav-stacked();
  border-radius: @border-radius-base;
  margin-bottom: @gutter;
  > li {
    margin-bottom: 0;

    > a {
      color: @gray-dark;
      border-radius: 0;
      border-top: 1px solid @gray-lighter;
      border-bottom: none !important;
      word-wrap: break-word;
      &:hover {
        background: @gray-lighter;
        .box-shadow(inset 0 0 0 1px @gray-light);
      }

      .ico.ico-delete {
        margin-top: 3px;
        color: darken(@gray-light,15%);
        &:hover {
          cursor: pointer;
          color: @gray-darker;
        }
      }
    }
    &:last-child a {
      border-bottom: 1px solid @gray-lighter !important;
    }
  }

  li + li {
    margin-top: 0;
  }
}

.rightbar .panel-body {
  #holding_attachments_actions {
    text-align: center;
  }

  #holding_attachments_list {
    margin: -@gutter -@gutter @gutter -@gutter;


    ul#attachments {
      > li:first-child > a {
        border-top: none !important;
      }
    }
  }
}

.field.attachments .input-group {
  height: 32px !important;
  .input-group-addon.js-filename {
    width: 100%;
    text-align: left;

    a {
      color: @text-color;
    }
  }

  .trade_attachments_actions {
    width: 120px;
  }
}

// This is required for user/edit and marketing pages for rendering the users company logo
img#pdf_logo_image {
  max-height: 73px;
  max-width: 283px;
}
