.a-coloring(@color) {
  a {
    color: darken(@color,5%);
    &:hover { color: darken(@color, 15%); }
  }
}

// no underlines in navs
// remove this if a (links) has no underlines anyway.
.nav {
  > li > a {
    text-decoration: none;
  }
}

.dropdown-menu {
  > li > a {
    text-decoration: none;
    &.menu-action {
      text-decoration: underline;
    }
  }
}

.alert-variant(@border) {
  position: relative;
  background-color: @alerts-background-color;
  border: 1px solid @alerts-border-color;
  color: currentColor;
  margin-bottom: @alerts-margin;

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    width: 8px;
    background-color: @border;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

.panel-title {
  > a {
    text-decoration: none;
  }
}

@gutter: (@grid-gutter-width / 2);

.padding-top(@multiplier:1) {
  padding-top: (@gutter*@multiplier);
}
.padding-bottom(@multiplier:1) {
  padding-bottom: (@gutter*@multiplier);
}
.padding-left(@multiplier:1) {
  padding-left: (@gutter*@multiplier);
}
.padding-right(@multiplier:1) {
  padding-right: (@gutter*@multiplier);
}

.make-spinning {
  -webkit-animation: fa-spin 1.3s infinite linear;
  -moz-animation: fa-spin 1.3s infinite linear;
  -o-animation: fa-spin 1.3s infinite linear;
  animation: fa-spin 1.3s infinite linear;
}

/*
 * col-xsm-X
 * Additional column style XSM for between phone and tablet
 * neccesary for iframes: width 600px
 */
.make-column {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left:  (@grid-gutter-width / 2);
  padding-right: (@grid-gutter-width / 2);
  float: left;
}

.make-xsm-column(@columns; @gutter: @grid-gutter-width) {
  .make-column();

  // Calculate width based on number of columns available
  @media (min-width: @screen-xs) {
    float: left;
    width: percentage((@columns / @grid-columns));
  }
}

.col-xsm-1  { width: percentage((1 / @grid-columns)); }
.col-xsm-2  { width: percentage((2 / @grid-columns)); }
.col-xsm-3  { width: percentage((3 / @grid-columns)); }
.col-xsm-4  { width: percentage((4 / @grid-columns)); }
.col-xsm-5  { width: percentage((5 / @grid-columns)); }
.col-xsm-6  { width: percentage((6 / @grid-columns)); }
.col-xsm-7  { width: percentage((7 / @grid-columns)); }
.col-xsm-8  { width: percentage((8 / @grid-columns)); }
.col-xsm-9  { width: percentage((9 / @grid-columns)); }
.col-xsm-10 { width: percentage((10/ @grid-columns)); }
.col-xsm-11 { width: percentage((11/ @grid-columns)); }
.col-xsm-12 { width: 100%; }

.col-xsm-1,
.col-xsm-2,
.col-xsm-3,
.col-xsm-4,
.col-xsm-5,
.col-xsm-6,
.col-xsm-7,
.col-xsm-8,
.col-xsm-9,
.col-xsm-10,
.col-xsm-11,
.col-xsm-12 {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left:  (@grid-gutter-width / 2);
  padding-right: (@grid-gutter-width / 2);
}

@media (min-width: @screen-phone) {
  .col-xsm-1,
  .col-xsm-2,
  .col-xsm-3,
  .col-xsm-4,
  .col-xsm-5,
  .col-xsm-6,
  .col-xsm-7,
  .col-xsm-8,
  .col-xsm-9,
  .col-xsm-10,
  .col-xsm-11 {
    float: left;
  }
}

@media (max-width: @screen-phone) {
  .col-xsm-1,
  .col-xsm-2,
  .col-xsm-3,
  .col-xsm-4,
  .col-xsm-5,
  .col-xsm-6,
  .col-xsm-7,
  .col-xsm-8,
  .col-xsm-9,
  .col-xsm-10,
  .col-xsm-11 {
    float: none;
    width: 100%;
  }
}

.make-gradient-vertical(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background: @color;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, @start),
    color-stop(1, @stop)
  );
  background: -ms-linear-gradient(bottom, @start, @stop);
  background: -moz-linear-gradient(center bottom, @start 0%, @stop 100%);
  background: -o-linear-gradient(@stop, @start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@stop,@start));
}

.make-gradient-horizontal(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
  background: @color;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, @start),
    color-stop(1, @stop)
  );
  background: -ms-linear-gradient(left, @start, @stop);
  background: -moz-linear-gradient(left, @start 0%, @stop 100%);
  background: -o-linear-gradient(left, @stop, @start);
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",@stop,@start));
}

.make-nav-pills-arrow-right(@bg-color:@brand-primary, @active-text-color:white, @hover-bg-color:@gray-lighter, @hover-text-color:@gray-darker) {
  > li {
    > a {
      margin-right: 10px;
      &:hover {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background: @hover-bg-color;
        color: @hover-text-color;
          &::after {
          position: absolute;
          top: 0px;
          right: -10px;
          display: inline-block;
          border-bottom: 16px solid transparent;
          border-left: 10px solid @hover-bg-color;
          border-top: 16px solid transparent;
          content: '';
        }
      }
    }
    &.active > a {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      color: @active-text-color;
      &::after {
        position: absolute;
        top: 0px;
        right: -10px;
        display: inline-block;
        border-bottom: 16px solid transparent;
        border-left: 10px solid @bg-color;
        border-top: 16px solid transparent;
        content: '';
      }
    }
  }
}


.make-font-awesome() {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-awesome-fw() {
  width: (18em / 14);
  text-align: center;
}

.make-sortable-icon() {
  .make-font-awesome();
    display: inline;
    content: @fa-var-sort;
    color: @gray;
    line-height: @line-height-base;

    @media (max-width: @screen-tablet) {
      bottom: @table-condensed-cell-padding;
  }
}

.make-sortable-basis() {
  cursor: pointer;

  &:hover {
    &:before {
      content: @fa-var-sort;
      color: @text-color;
    }
  }

  > div {
    display: inline-block;
  }

  &:before,
  &:after {
    .make-sortable-icon();
  }

  &.sortasc:before,
  &.sortasc:after {
    content: @fa-var-caret-up; // @fa-var-sort-asc;
    color: @text-color;
  }

  &.sortdesc:before,
  &.sortdesc:after {
    content: @fa-var-caret-down; // @fa-var-sort-desc;
    color: @text-color;
  }

  &.sort-off:before,
  &.sort-off:after {
    display: none;
  }
}

.show-sortable-left() {
  .make-sortable-basis();
  padding-left: 16px;

  > *:first-child { // spacing between :before and first child
    margin-left: 4px;
  }

  &:before {
    margin-left: -10px;
  }

  &:after {
    display: none;
  }
}

.show-sortable-right() {
  .make-sortable-basis();
  padding-right: 8px;

  &:before {
    display: none;
  }

  > *:last-child { // spacing between last element and :after
    margin-right: 4px;
  }

  &:after {
    margin-right: -10px;
  }
}

.remove-modal-inner-padding {
  margin-left:  -@modal-inner-padding;
  margin-right: -@modal-inner-padding;
  padding-left: @modal-inner-padding;
  padding-right: @modal-inner-padding;
}
