/*
 * From previews frontend!
 * As you see ... :-)
 */

/* Bootstrap additions */
@invoice-primary: '#F5A623';

.invoice {
  h1,h2,h3,h4 {
    font-weight: 800;
  }

  table td,
  table th {
    padding: (@gutter/2);
  }
}


/* STANDALONE INVOICE STYLES - IN APP */
body.invoice {
width: 700px;
margin: 0 auto;
padding: 20px 0 30px;
text-align: center;
}

.printinvoice {
text-align: left;
}


/* ALL INVOICE STYLES - IN APP */

.email, .invoice, body.invoice, .lastccattempt {
position: relative;
background: #fff;
}

.invoice {
}

.lastccattempt {
padding: 20px;
}

.invoice .overdue {
text-transform: uppercase;
position: absolute;
top: -7px;
left: 120px;
color: #fff;
font-size: 1.5em;
background: url("~images/arrow.png") top right no-repeat;
padding: 0px 30px 0px 5px;
height: 48px;
line-height: 48px;
}

.invoiceheader h1 {
font-size: 4.5em;
color: @invoice-primary;
margin: -10px 0 0 10px;
padding: 0px;
letter-spacing: -4px;
line-height: 1em;
}

.invoiceheader h2 {
font-size: 3em;
color: #666;
margin: -5px 0px 0px 10px;
padding: 0px;
letter-spacing: -2px;
line-height: 1em;
}

.invoiceheader .details h3 {
margin: 15px 0px 0px 0px;
padding: 0px;
color: #666;
}


.invoiceheader {
  min-height: @gutter;
  padding: 0px;
  margin: 0 0 15px 0;
}

.payment {
padding: 15px 0px;
margin: 40px 0px 15px 0px;
border-top: 3px solid #efefef;
}

.paymentdetails {
border-top: 1px solid #efefef;
color: #666;
padding: 15px 0px 0px;
margin: 150px 0px 0px 0px;
}

.paymentdetails .fourcolumn {
float: right;
}


.invoice .lineitem td {
border: 1px solid #e1e1e1;
background: #efefef;
}

.invoice tr.amount_due,
.invoice tr.status {
  font-size: 1.5em;
  .text {
    padding-right: 25px;
  }
  .gst {
    font-size: 0.6em;
  }
  .paid {
    color: @state-success-text;
  }
  .unpaid {
    // color: @state-danger-text;
  }
  td {
    position: relative;
  }
}

.invoice {
  tr.sales_tax_data {
    font-size: 1.5em;

    td {
      padding: 0;
      position: relative;
      text-align: right;
    }
  }

  tr.sales_tax_data#total_amount {
    td {
      padding-top: 25px;
    }
  }
}

.paymentdetails h3 {
color: @invoice-primary;
margin: 0px;
}
.thanks .txt {
color: #666 !important;
color: @invoice-primary;
font-weight: bold;
padding-right:45px;
margin-bottom: -20px;
}

.fourcolumn .thanks {
text-align: right;
padding: 0px 15px;
margin-left: 15px;
background: #fff;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px;
}


.fourcolumn .thanks h2 {
text-align: right;
background-position: 100% 100%;
}



.payment .details {
text-align: right;
color: #666;
padding-top: 2px;
}

.invoiceheader .name p {
font-size: 1.2em;
margin-left: 10px;
color: #666;
}

.invoiceheader .details, .payment .thanks {
float: right;
width: 40%;
color: #666;
padding-top: 1em;
}
.payment .thanks {
width: 47%;
}

.invoiceheader .name, .payment .details {
float: left;
width: 50%;
}

.invoice table {
border: 0px;
margin: 0px 0px;
}

.invoice table td {
border: 0px;
color: #666;
vertical-align: top;
}

.invoice table td.rightcol {
text-align: right;
}

.invoice table th {
border: 0px;
text-align: left;
text-transform: uppercase;
color: #666;
border:1px solid #ffd9b9;
background: #ffebda;
}

.overdueinvoice .total, .declinedcc {
color: #bc0000;
}
