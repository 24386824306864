/*
 * Layout
 */

/*
 * Sticky footer styles
 */
html,
body {
  height: 100%;

  /* overwrite bootstrap bullshit */
  .progress {
    overflow: auto;
  }
}

// Allows the content to take the full space and maintain a sticky footer on all screen sizes
.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .footer-container {
    margin-top: auto;
  }
}

#page {
  background: @body-bg;

  @media (max-width: @screen-desktop) {
    background: @white;
  }
}
/* END Sticky footer styles */

h1,
h2,
h3 {
  color: @headline-color;
}

.nav-pills > li > a {
  border-radius: @border-radius-base;
}

.overlay {
  display: none;
}

// remove dotted borders in Firefox
:focus{ outline:0!important; }

.unbold-label {
  font-weight: normal;
}

.sidebar-form {
  margin-left: 1em;
  line-height: 200%;
}

.publishing {
  color: #D0D0D0;
}

.publish-button {
  margin-top: 1em;
}

.user-form-subgroup {
  font-weight: bold;
}

.user-form-checkbox {
  font-weight: normal;
  margin-top: 1em;
  text-align: right;
}

.signup-button {
  white-space: normal;
  height: 12em;
}

.centred-or {
  margin-left: 0.5em;
  color: #f9914a;
  font-size: 14pt;
}

.dyn_prompt {
  font-size: 12pt;
}

.dyn_buttons {
  margin-top: 1em;
}

.dyn_select {
  width: 90%;
}

img.upload-image {
  border: solid 1px;
  margin-left: 0 !important;
}

table.table-full-width {
  width: 100%;
}

div.original-corporate-actions {
  margin-top: 1em;
  margin-bottom: 1.5em;
}
