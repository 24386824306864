/*
 **************
 * Block styles
 **************
 */

.width-col-1 { width: percentage((1 / @grid-columns))!important; }
.width-col-2 { width: percentage((2 / @grid-columns))!important; }
.width-col-3 { width: percentage((3 / @grid-columns))!important; }
.width-col-4 { width: percentage((4 / @grid-columns))!important; }
.width-col-5 { width: percentage((5 / @grid-columns))!important; }
.width-col-6 { width: percentage((6 / @grid-columns))!important; }
.width-col-7 { width: percentage((7 / @grid-columns))!important; }
.width-col-8 { width: percentage((8 / @grid-columns))!important; }
.width-col-9 { width: percentage((9 / @grid-columns))!important; }
.width-col-10 { width: percentage((10 / @grid-columns))!important; }
.width-col-11 { width: percentage((11 / @grid-columns))!important; }
.width-col-12 { width: percentage((12 / @grid-columns))!important; }
@media (max-width: @screen-desktop) {
  .width-col-1 { width: 100%!important; }
  .width-col-2 { width: 100%!important; }
  .width-col-3 { width: 100%!important; }
  .width-col-4 { width: 100%!important; }
  .width-col-5 { width: 100%!important; }
  .width-col-6 { width: 100%!important; }
  .width-col-7 { width: 100%!important; }
  .width-col-8 { width: 100%!important; }
  .width-col-9 { width: 100%!important; }
  .width-col-10 { width: 100%!important; }
  .width-col-11 { width: 100%!important; }
  .width-col-12 { width: 100%!important; }
}

.make-width-center(@width) {
  width: @width;
  margin-right: auto;
  margin-left: auto;
  @media(max-width: @screen-tablet) {
    width: auto;
  }
}

.width-xxs-center{ .make-width-center(360px); }
.width-xs-center{ .make-width-center(@screen-xs); }
.width-sm-center{ .make-width-center(@screen-sm); }
.width-md-center{ .make-width-center(@screen-md); }

.narrow-content,
.narrow-content-md {
  .make-md-column(10);
  .make-md-column-offset(1);

  .make-lg-column(8);
  .make-lg-column-offset(2);
}

.narrow-content-lg {
  .make-md-column(12);
  .make-md-column-offset(0);

  .make-lg-column(10);
  .make-lg-column-offset(1);
}

.narrow-content-sm {
  .make-md-column(8);
  .make-md-column-offset(2);

  .make-lg-column(6);
  .make-lg-column-offset(3);
}

.narrow-content-xs {
  .make-md-column(6);
  .make-md-column-offset(3);

  .make-lg-column(4);
  .make-lg-column-offset(4);
}

.narrow-content-xxs {
  .make-sm-column(6);
  .make-sm-column-offset(3);

  .make-md-column(4);
  .make-md-column-offset(4);
}

.panel {
  .box-shadow(none);

  .actions:last-child {
    margin-bottom: 0;
  }
}

.panel-group {
  margin-bottom: @gutter;
}

.panel-group .panel + .panel {
  margin-top: @gutter;
}

.box {
  .panel();
  .panel-default();
  .panel-body();
  border-color: @gray-light;

  .box-shadow(none);

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child,
  p:first-child,
  ul:first-child {
    margin-top: 0;
    margin-top: 0;
  }

  .actions:last-child {
    margin-bottom: 0;
  }

  &.page-wrapper .box {
    &:not(.box-success):not(.box-info):not(.box-danger) {
      .box-fill();
    }
  }
}

.box-login, .box-signup {
  border-radius: @border-radius-base * 3; // 9px
  border: unset;
  padding: @space-6;
}

.box-no-margin {
  margin:0;
}

.iframed .page-wrapper.box,
.modal .page-wrapper.box {
  border: none;
}

.page-wrapper.box .box .box {
  background: darken(@body-bg, 5%);
}
.page-wrapper.box .box .box .box { // that's it, don't nest it deeper ;-)
  background: @body-bg;
}

.box-fill {
  background: @gray-lighter;
  border-color: @gray-light;
}

.box-empty {
  background: transparent!important;
}

.box-info {
  background: @state-info-bg !important;
  border-color: @state-info-border;
}

.box-success {
  background: @state-success-bg !important;
  border-color: @state-success-border;
  color: @state-success-text;

  .btn-default {
    .btn-success();
  }
}

.box-danger {
  background: @state-danger-bg !important;
  border-color: @state-danger-border;
  &,
  a {
    color: @state-danger-text;
  }

  .btn-default {
    .btn-danger();
  }
}

.box-info,
.box-success,
.box-danger {
  h1,h2,h3,h4,h5,h6 {
    color: inherit;
  }
}

.box-heading {
  margin-bottom: @gutter;
  h1,h2,h3,h4 {
    margin-top: 0;
  }
}

.box-os-window {
  border-radius: @border-radius-large;
  .box-shadow(0 7px 15px @gray-light);

  .box-os-heading {
    margin: -@gutter -@gutter @gutter;
    padding: @gutter;
    .border-top-radius(@border-radius-large);
    .make-gradient-vertical(@gray-lighter, @gray-lighter, white);
    .box-shadow(inset 0 1px 3px white);
    border-bottom: 1px solid @gray-light;

    text-shadow: 0 1px 0px white;
    font-weight: bold;
    color: @gray-dark;
  }
}

/*
 * Summaries
 */
.summaries {
  &.portfolio-overview {
    margin-bottom: (@gutter*2);
  }

  table.figures {
    margin-bottom: 0!important;

    .benchmarking__cta {
      padding: 10px 0;

      // I'm sorry for the specificty, but someone else broke it years ago.
      text-align: center !important;
      @media (max-width: @screen-tablet) {
        text-align: left !important;
      }
    }

    > thead th {
      text-align: center;
    }

    > tbody > tr:nth-child(even) {
      > td,
      > th {
        background-color: @table-bg-accent;
      }
    }

    .td-naming {
      text-align: left;
      width: 375px;
      @media (max-width: @screen-lg-desktop) { width: 300px; }
      @media (max-width: @screen-desktop) { width: 225px; }
    }

    td {
      padding: 6px 3px;
      text-align: center;
      vertical-align: middle;

      .heading {
        @media (max-width: @screen-phone) {
          .h5();
        }
        .h3();
        .dull {
          font-style: @font-size-md!important;
        }
      }
      .value {
        display: block;
        line-height: @font-size-xxl;
        .percent {
          .h3();
        }
      }
      .title {
        display: block;
        .small();
        .label();
        white-space: normal;
        font-weight: normal;
        text-transform: uppercase;
      }

      &.symbol {
        width: 30px;

        span {
          color: @gray;
          font-size: @font-size-xxl;
        }
      }
    }
  }
  .graph {
    min-height: 300px; // height of svg graph

    .highcharts-container {
      margin-left: (@gutter/2);
      margin-right: (@gutter/2);
    }

    #highchart_chart_loading {
      padding-top: 130px; // .graph height - spinner ico height/2 (26px/2 = 13) - padding/2
    }
  }

  .bondstats {
    display: table;
    table-layout: fixed;
    width: 100%;

    section {
      display: table-cell;
      padding: 0 (@gutter/2) @gutter;
      vertical-align: middle;

      @media (max-width: @screen-phone) {
        display: block;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      @media (min-width: @screen-phone) and (max-width: @screen-tablet) {
        display: inline-block;
        width: 50%;

        padding-right: (@gutter/2);
        padding-left: 0;

        &:nth-child(2n) {
          padding-left: (@gutter/2);
          padding-right: 0;
        }
      }

      &:first-child { padding-left:0; }
      &:last-child { padding-right:0; }

      .box {
        padding: (@gutter*2) (@gutter/2);
        margin-bottom: 0;

        @media (max-width: @screen-tablet) {
          padding: 15px;
        }
      }

      .value {
        display: block;
        .h1();
      }

      .title {
        display: block;
        .small();
        .label();
        white-space: normal;
        font-weight: normal;
        text-transform: uppercase;
      }

    }
  }
}


/*
 *  Critieria bar
 */
.criteria-bar {
  margin-top: 0;
  margin-bottom: @gutter;
  .pull-right {
    margin-left: 5px;
  }
  .form-inliner {
    @media (min-width: @screen-tablet) {
      .form-group {
        padding-right: 0px;
      }
    }
    .criteria-control {
      width: 100%!important;
    }
  }
}

/*
 * Advanced criteria bar
 */
.criteria-bar {
  .advanced-options-toggle-link {
    white-space: nowrap;
  }

  .advanced-options {
    border-top: 1px solid @gray-light;
    width: 66%;
    position: relative;
    margin: @gutter auto 0;

    @media (max-width: @screen-desktop) {
      width: 100%;
    }

    > .advanced-wrapper {
      position: absolute;
      overflow: hidden;
      left: (@gutter/2);
      right: (@gutter/2);
      margin: 0 (@gutter/2);

      > .advanced-container {
        .make-gradient-vertical(@gray-lighter, @gray-lighter, #fff);
        .transition(~"box-shadow 1s");
        border: 1px solid @gray-light;
        padding: @gutter;
        position: absolute;
        width: 100%;
        z-index: @zindex-criteria-bar-advanced;
        &.open {
          @media (min-width: @screen-desktop) {
            .box-shadow(0 8px 12px -6px rgba(0,0,0,.2));
          }
        }
      }
      > .advanced-shadow-bar {
        .make-gradient-vertical(transparent, transparent, rgba(0,0,0,.1));
        position: absolute;
        z-index: (@zindex-criteria-bar-advanced+1);
        height: 8px;
        width: 100%;
      }

    }
  }
}

/*
 * Inception
 */
.inception {
  display: table;
  width: 100%;

  > .inception-header {
    display: table-cell;
    vertical-align: top;

    .subheadline {
      margin-top: -.75em;
    }
  }

  h1,h2,h3,h5,h6,p {
    margin-bottom: @gutter;
  }
}

@media (max-width: @screen-phone) {
  .inception {
    display: block;
    padding-bottom: @gutter;

    .inception-header {
      display: block;
    }
  }
}

/*
 * Flash messages
 */

.flashmessage {
  .alert-variant(@alerts-info-border);
  
  h1,h2,h3,h4,h5,h6,
  .holding-code {
    color: inherit;

    &:nth-of-type(1) {
      margin-top: 2px;
    }
  }
}

.warningmessage,
.flashmessage.danger,
.flashmessage.error {
  .alert-variant(@alerts-danger-border);

  .btn-default {
    .btn-danger();
  }
}

.successmessage,
.flashmessage.success {
 .alert-variant(@alerts-success-border);

  .btn-default {
    .btn-success();
  }
}

.notification,
.flashmessage.info {
  .alert-variant(@alerts-info-border);
}

.flashmessage.warning {
  .alert-variant(@alerts-warning-border);

  .btn-default {
    .btn-warning();
  }

  position: relative;
  .upgrade-button {
    // float: right;
    position: absolute;
    right: 13px;
    top: 3px;
  }
}

.alert,
.flashmessage {
  padding: @alerts-padding;
  border-radius: @alerts-border-radius;

  ul,
  ol {
    margin-bottom: 0;
    margin-top: 0;
  }

  button.close {
    margin-top:-3px;
    margin-right: -(@gutter/3+1);
  }
}

.flashmessage.secondary {
  .alert-variant(@alerts-info-border);

  .btn-default {
    .btn-secondary();
  }
}


/*
 * Dropdown default style
 */
// The dropdown menu (ul)
.dropdown-menu {
  .box-shadow(0 0 60px rgba(0,0,0,.2));
}

/*
 * Progressbar (1. Lorem | 2. Ipsum | 3. Dolor)
 * Example in plans#new
 */
.progressbar {
  padding-bottom: @gutter;
  margin-bottom: @gutter;
  border-bottom: 1px solid @gray-light;

  > ul {
    .nav();
    .nav-pills();
    .nav-justified();
    .h3();

    li.bullet {

      a:hover {
        background: none;
      }


      > section {
        text-align: center;
        color: darken(@gray-light,10%);
      }

      .badge {
        .h3();
        padding-left: (@gutter/2);
        padding-right: (@gutter/2);
        background: @gray-light;
        color: white;
      }

      &.current {
        > section {
          color: @gray-darker;
        }

         .badge {
          background: @brand-warning;
        }
      }

      &.completed {
        a {
          &:after {
            .make-font-awesome();
            .font-awesome-fw();
            content: @fa-var-check;
            margin-left: 5px;
          }
        }
      }

    }
  }
}
