#show_holding {

  .panel-body-container {
    @media (max-width: @screen-xs) {
      border-bottom: 1px solid transparent;
    }
    border-bottom: 1px solid @nav-tabs-border-color;

    .nav.nav-tabs {
      li.active > a {
        @media (max-width: @screen-xs) {
          border-bottom: 1px solid @nav-tabs-border-color;
        }
        border-bottom: 1px solid transparent;
      }
    }
  }

  #sidebar_panels {

    .details-horizontal {
      width: 100%;
      margin-bottom: @gutter;
    }

    #holding-average-purchase-price {
      margin-bottom: @gutter;
    }

    dl {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    dd {
      margin-bottom: @gutter;

      &:last-child {
        margin-bottom: 0;
      }
    }

  }
}
