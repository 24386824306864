#payout__payout_form {
  .fields {
    margin-bottom: (@gutter*2);
  }

  .field-total {
    border-top: 3px double @gray-light;
    padding: (@gutter/2) @gutter (@gutter/2) 0;
    text-align: right;

    .dull {
      color: @text-color;
    }
  }
}
