.make_mgmt_label(@color) {
  display: inline-block;
  border-radius:100%;
  height: @font-size-md;
  width: @font-size-md;
  background: @color;
}

.label_indicators {
  float: left;
  margin-right: 5px;
  max-width: 40px; // 3 labels per row
  .wrap-normal();
}

.single_label {
  margin-bottom: -3px;

  .list-manage-labels & {
    margin-bottom: -2px;
  }
}

.label_text {
  margin-left: 3px;
}

.label_colour_0 {  .make_mgmt_label(BlueViolet); }
.label_colour_1 {  .make_mgmt_label(Chartreuse ); }
.label_colour_2 {  .make_mgmt_label(Gold); }
.label_colour_3 {  .make_mgmt_label(Cyan); }
.label_colour_4 {  .make_mgmt_label(Crimson); }
.label_colour_5 {  .make_mgmt_label(DarkCyan); }
.label_colour_6 {  .make_mgmt_label(OrangeRed ); }
.label_colour_7 {  .make_mgmt_label(LightGreen); }
.label_colour_8 {  .make_mgmt_label(MediumVioletRed); }
.label_colour_9 {  .make_mgmt_label(Indigo); }
.label_colour_10 { .make_mgmt_label(SkyBlue); }
.label_colour_11 { .make_mgmt_label(Khaki); }
.label_colour_12 { .make_mgmt_label(LawnGreen); }

.label_colour_13 { .make_mgmt_label(BlueViolet); }
.label_colour_14 { .make_mgmt_label(Chartreuse); }
.label_colour_15 { .make_mgmt_label(Gold); }
.label_colour_16 { .make_mgmt_label(Cyan); }
.label_colour_17 { .make_mgmt_label(Crimson); }
.label_colour_18 { .make_mgmt_label(DarkCyan); }
.label_colour_19 { .make_mgmt_label(OrangeRed); }
.label_colour_20 { .make_mgmt_label(LightGreen); }
.label_colour_21 { .make_mgmt_label(MediumVioletRed); }
.label_colour_22 { .make_mgmt_label(Indigo); }
.label_colour_23 { .make_mgmt_label(SkyBlue); }
.label_colour_24 { .make_mgmt_label(Khaki); }
.label_colour_25 { .make_mgmt_label(LawnGreen); }

.label_colour_26 { .make_mgmt_label(BlueViolet); }
.label_colour_27 { .make_mgmt_label(Chartreuse); }
.label_colour_28 { .make_mgmt_label(Gold); }
.label_colour_29 { .make_mgmt_label(Cyan); }
.label_colour_30 { .make_mgmt_label(Crimson); }
.label_colour_31 { .make_mgmt_label(DarkCyan); }
.label_colour_32 { .make_mgmt_label(OrangeRed); }
.label_colour_33 { .make_mgmt_label(LightGreen); }
.label_colour_34 { .make_mgmt_label(MediumVioletRed); }
.label_colour_35 { .make_mgmt_label(Indigo); }
.label_colour_36 { .make_mgmt_label(SkyBlue); }
.label_colour_37 { .make_mgmt_label(Khaki); }
.label_colour_38 { .make_mgmt_label(LawnGreen); }

.manage-labels-bar {
  margin-top: @gutter;
}

.list-manage-labels {
  //.make-row();
  .list-unstyled();

  li {
    //.make-md-column(3);
    float: left;
    margin-right: (@gutter/2);
    padding: 4px 12px 3px;
    border-radius: @border-radius-base;
    border: 1px solid @gray-light;
    background: #fff;
    .single_label {
      margin-right: 5px;
    }
  }
}
