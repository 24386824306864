/*
 * Content area
 */
.content-header-area {
  background: @white;
  border-bottom: 1px solid @gray-light;

  @media (max-width: @screen-desktop) {
    margin-bottom: 0;
  }

  #content_header {
    &:extend(.container all);
    padding-left: 0;
    padding-right: 0;

    @media(max-width: @screen-desktop) {
      padding-left: (@gutter/2);
      padding-right: (@gutter/2);
    }

    @media (max-width: @screen-phone) {
      h1,h2 {
        .h3();
      }
    }

    h1,h2,h3,h4,h5,h6,p {
      margin-bottom: @gutter;
    }
  }

  .breadcrumb {
    background: none;
    margin: (@gutter*1.25) 0 0;
    padding: 0 0 @gutter;
  }
}

.content-area {
  .container-fixed();
  padding-top: (@gutter*2);
  padding-left: 0;
  padding-right: 0;

  @media (max-width: @screen-desktop) {
    padding-top: (@gutter);
  }

  @media (max-width: @screen-phone) {
    padding-top: (@gutter/2);
    padding-left: (@gutter/2);
    padding-right: (@gutter/2);
    padding-bottom: 72px; // padding for the bottom mobile navigation
  }

  .mainbar {
    .make-md-column(12);

    &.mainbar-with-rightbar {
      .make-md-column(9);
    }

    @media(min-width: @screen-desktop) {
      .data-table {
        margin-right: 0 !important;
      }
    }
  }

  .nav-tabs {
    > li > a,
    > li > a:hover {
      border-bottom-color: transparent;
    }
    > li.active > a {
      background: @white;
      margin-bottom: -1px;
      border-bottom: 2px solid @white;
    }
  }

  .box .nav-tabs {
    > li > a
    > li > a:hover {
      border-bottom-color: transparent;
    }
    > li.active > a {
      background: @nav-tabs-active-link-hover-bg;
      margin-bottom: -1px;
      border-bottom: 1px solid @nav-tabs-link-hover-border-color;
    }
  }

  .rightbar {
    .make-md-column(3);
    @media(min-width: @screen-desktop) {
      padding-left: 0;
    }
  }
  @media(max-width: @screen-desktop) {
    .page-wrapper.box {
      border: none;
      .box-shadow(none);
      padding-left: 15px;
      padding-right: 15px;

      .data-table {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media (min-width: @screen-tablet) {
    width: @container-tablet;
  }

  @media (min-width: @screen-desktop) {
    width: @container-desktop;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: @screen-lg-desktop) {
    width: @container-lg-desktop;
  }
}
/*
 * Tooltip adjustments
 */

.tooltip {
  position: fixed;
}

input[type='radio'].import_options_radio {
  margin-right: 0.5em;
}

.import_options_item {
  padding-right: 2em;
  font-weight: bold;
}

.import_options_label {
  font-weight: bold;
  padding-right: 2em!important;
}

table.explain_table {
  border: solid;
  border-width: 1pt;
}

table.explain_table th, table.explain_table td {
  padding-right: 1em;
  border-right: 1px dotted;
}

table.info_table {
  border: 1px solid;
}

tr.info_header_row {
  border: 1px solid;
}

th.info_header {
  border: 1px dotted;
  background-color: #1ac0bc;
  padding-left: 2px;
}

td.info_cell {
  border-right: 1px dotted;
  padding-left: 2px;
  padding-right: 1em;
}

.logo_centred {
  display: block;
  margin-left: auto;
  margin-right: auto
}

h1.portfolio_name {
  font-size: 36pt;
  text-align: center;
}

div.cta {
  text-align: center;
  vertical-align: middle;
  background-color: @brand-warning;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
  max-width: 38em;
  height: 41px;
  line-height: 43px;
  text-transform: uppercase;
}

div.cta:hover {
  background-color: #f5a623;
}

a.cta {
  font-size: 16pt;
  color: @white;
  text-decoration:none;
}
