/*
 * Collabsible penals
 * (bootstrap default collapsible solution)
 */
.panel {
  &.closed,
  &.opened {
    .panel-heading {
      .ico-collapse {
        float: right;
        color: @gray;
      }
      .panel-title {
        a {
          display: block;
          width: auto;
          margin: -10px -15px;
          padding: 10px 15px;
          .h4();
        }
      }
    }
  }
}

/*
 * Collabsible divs
 */
.collapsible {

  .heading {
    border-bottom: 1px solid @gray-light;
    padding: (@gutter/2) (@gutter/2) (@gutter/2) (@gutter+1);

    &:before {
      .make-font-awesome();
      .font-awesome-fw();
      content: @fa-var-caret-right;
    }

    &:hover {
      cursor: pointer;
      background: @gray-lighter;
      .box-shadow(inset 0 0 0 1px @gray-light);
    }
  }

  &.shown .heading {
    border-bottom-color: transparent;
    &:before {
      content: @fa-var-caret-down;
    }
  }

  .body {
    display: none;
    border-bottom: 1px solid @gray-light;
    padding: @gutter @gutter (@gutter*2);
    table tr > td {
      vertical-align: top;
    }
  }
}


.panel-collapse {
  .panel-body {
    .collapsible {
      margin: 0 -@gutter;
    }
  }
}
