/*
 * Modal drag styles
 */
// .modal * {
//   border: 1px dashed red;
// }

.modal {
  -webkit-overflow-scrolling: touch!important;
  overflow: scroll!important;
}

body.modal-open #modal_overlay {
  margin-right: 0px !important;
  overflow: scroll !important;
  position: fixed;
  width: 100%;
  height: 100%;
}

.modal.in .modal-dialog {
  -webkit-transform: none;
  transform: none;
  -webkit-transition: none;
  transition: none;
}

.modal .page-wrapper.box .box {
  background: white;
}

.modal-content.ui-draggable-dragging {
  .transition(none);

  //.transition(none);
  background: transparent;
  .box-shadow(none);

  //border-style: dashed;
  //border-color: @gray;

  .modal-body {
    visibility: hidden;
  }
}

.modal-content {
  border-radius: @modal-content-border-radius;

  .modal-dragfield {
    position: absolute;
    z-index: (@zindex-modal+1);
    padding: 0 @gutter;
    width: 100%;
    text-align: center;
    cursor: move;
    color: @gray-light;
    max-height: @gutter;

    &:hover {
      color: @gray;
      cursor: move;
    }
  }
}


/*
 * Modal general
 */
@media(min-width: @screen-desktop) {
  .modal-dialog {
    width: 748px;
    padding-top: 50px;
  }
}
@media (min-width: @screen-tablet) {
  .modal-dialog {
    width: 748px;
    padding-top: 50px;
  }
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal-content {
  overflow: hidden;
  .transition(min-height 0.6s ease-in);

  border-width: 2px;
  .box-shadow(none);

  @media(min-width: @screen-desktop) {
    .box-shadow(0 3px 120px rgba(0,0,0,.2));
  }

  div.modal-spinner {
    position: absolute;
    z-index: (@zindex-modal+1);
    width: 100%;
    padding: 115px 0;
    text-align: center;
  }

  .modal-header.empty {
    position: absolute;
    z-index: (@zindex-modal+2);
    right: 0;
    border: none;
    background: transparent;

    button.close {
      margin-right: 2px;
    }
  }

  .modal-body {
    min-height: 57px;
  }
}

.modal-footer {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.modal-backdrop.in {
  .opacity(.2);
}

/*
 * Modal styles
 */
.modal.modal-primary .modal-content {
  border-color: @brand-primary;
}
.modal.modal-secondary .modal-content {
  border-color: @brand-secondary;
}
.modal.modal-success .modal-content {
  border-color: @brand-success;
}
.modal.modal-warning .modal-content {
  border-color: @brand-warning;
}
.modal.modal-danger .modal-content {
  border-color: @brand-danger;
}
.modal.modal-info .modal-content {
  border-color: @brand-info;
}

/*
 * view style adjustments
 */
body.iframed {
  background: white;
}

.modal,
.iframed {
  height: auto;

  #page {
    margin: 0;
    padding-bottom: 0;
  }
  .page-wrapper {
    float:none !important;
    margin-left: 0!important;
    width: auto!important;
    padding: 0;
    margin: 0 !important;
  }

  // .box-heading {
  //   margin-bottom: (@gutter*2);
  // }

  // Modal background
  .modal-backdrop {
    background-color: white;
    // Fade for backdrop
    &.in { .opacity(.75); }
  }

  .fields {
    > .field {
      margin-bottom: 0;
    }
    // margin-bottom: (@gutter*2);
  }
}

@media (max-width: @screen-xs) {
.js-payout-fields-control {
  .popover-button {
    white-space: normal;
  }
}
}
