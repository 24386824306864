//ul-element
.list-instrument-news {
  padding-left: 0;
  list-style: none;

  > li {
    border-top: 1px solid @gray-lighter;
    padding-top: @gutter;
    margin-bottom: @gutter;

    &:first-child {
      border-top: none;
      padding-top: 0;
    }

    h1,h2,h3,h4,h5 {
      margin-top: 0;
    }

    .badge-livewire {
      background: #19202d;
    }

    .badge-firstlinks {
      background: #01589a;
    }

    .badge-motleyfool_uk {
      background: #0187da;
    }
  }
}


#instrument_show {
  .no-padding {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .holding-headline-container {
    padding: @gutter;
  }
  .holding-performance-container {
    .nav-tabs {
      margin-top: @gutter;
      margin-bottom: @gutter;

      > li {
        > a {
          border-bottom: 1px solid @nav-tabs-border-color;
        }
      }
    }
  }
  .holding-info-container {
    .col-info {
      dl dd {
        padding-bottom: (@gutter/4);
        margin-bottom: (@gutter/4);
        border-bottom: 1px solid @gray-light;
      }
      table.details-horizontal {
        width: 100%;
        margin-bottom: 0;
      }
    }
  } // .holding-info-container
}

// within overlay:
.modal #instrument_show {

  .holding-body {
    .holding-headline-container {
      .remove-modal-inner-padding();
      padding-top: 0;
      padding-bottom: 0;
      margin-top: @gutter;
    }
    .holding-info-container {
      .col-graph {
        padding-left: 5px;
        .graph .highcharts-container {
          margin: 0;
        }
        .bondstats {
          section {
            display: block;
            width: 48%;
            padding-left: 0!important;
            padding-right: @gutter!important;
            float: left;

            .box {
              padding-top: (@gutter*1.5);
              padding-bottom: (@gutter*1.5);
            }
          }
        }
      }
      .col-info {
        padding-left: 0;
      }
    }

    .holding-performance-container {
      .remove-modal-inner-padding();
      border-bottom: 1px solid @nav-tabs-border-color;

      padding-top: @gutter;
      margin-bottom: (@gutter*2);

      .summaries {
        table.figures {
          // reset previous styling
          background: transparent;
          margin-bottom: @gutter!important;
          border-color: transparent;

          .td-naming {
            text-align: left;
            width: 200px!important;
            .heading {
              font-size: @font-size-md;
            }
          }
        }
      }

      .nav.nav-tabs {
        .remove-modal-inner-padding();
        margin-top: 0;
        margin-bottom: 0;
        &.nav-justified{
          width: auto;
        }
        > li > a {
          border-bottom: none;
          margin-bottom: -1px;
        }
        > li.active > a {
          border-bottom-color: transparent;
        }
      }

    }
  }

}
