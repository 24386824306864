/*
 * This file is for the Rails side of React; any overrides that must be done before React loads.
 */

body.custom_groups #page .content-area {
  width: auto;
  padding: 0;
}

/*
 * NOTE: These placeholders are not removed after mounting is complete, so they should be under-sized and have no background!
 */
 .react-placeholder__30 {
   min-height: 30px;
 }

 .react-placeholder__50 {
   min-height: 50px;
 }

 .react-placeholder__70 {
   min-height: 70px;
 }

.react-placeholder__100 {
  min-height: 100px;
}

.react-placeholder__130 {
  min-height: 130px;
}

.react-placeholder__200 {
  min-height: 200px;
}

.react-placeholder__300 {
  min-height: 300px;
}

.react-placeholder__container {
  min-height: 50vh;
}
