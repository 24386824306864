//
// Other minor models here:
//

// Google ReCAPTCHA
#recaptcha_container {
  background: url("~images/spinner_32x32.gif") no-repeat 50% 50%;
  width: 304px;
  min-height: 78px;
  margin: auto;
}
