/*
 *************
 * Typo styles
 *************
 */

a {
  text-decoration: underline;
  &.btn,
  &.list-group-item {
    text-decoration: none;
  }
}

h1, .h1 { font-size: @font-size-h1 }
h2, .h2 { font-size: @font-size-h2 }
h3, .h3 { font-size: @font-size-h3 }
h4, .h4 { font-size: @font-size-h4 }
h5, .h5 { font-size: @font-size-h5 } 
h6, .h6 { font-size: @font-size-h6 }

h1,h2,h3,h4,h5,h6 {
  &.subheadline {
    margin-top: 0;

    .holding-code {
      color: @gray;
      font-weight: normal;
    }
  }
}

.lead {
  font-weight: 300;
  font-size: @font-size-md;
  @media (min-width: 768px) {
    font-size: @font-size-md;
  }
}

.btn-secondary {
  .button-variant(@btn-secondary-color; @btn-secondary-bg);
}

.btn-lg {
  // same as original bootstrap, but with NO large border radius!
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-lg; @line-height-large; @border-radius-base);
}

.small {
  font-size: @font-size-xs;
}

.dull,
.footnote {
  color: @gray;
}

.text-upcase {
  text-transform:uppercase!important;
}

span.discount {
  background: lighten(@brand-success, 30%);
  color: spin(darken(@brand-success,10%), 20%);
  padding: 2px (@gutter/2);
}

.holding-code {
  color: @brand-primary;
  font-weight: bold;
}
.holding-market {
  .badge();
}

.holding-expired {
  color: lighten(@gray, 15%)!important;
  .holding-code,
  .holding-name {
    color: lighten(@gray, 15%)!important;
  }
}

.holding-limited {
  background-color: @state-warning-bg;
}

// beta flag:
.badge-beta {
  &, a&, button& {
    .badge();
    background: @brand-warning;
  }

  a&:hover,
  a&:focus {
    background: lighten(@brand-warning, 10%);
  }
}

// new feature flag
.new-feature {
  .badge();
  color: @white;
  background: @brand-primary;
}

// admin flag
.badge-admin {
  .badge();
  color: @white;
  background: @brand-danger;
}

a abbr:hover {
  cursor: pointer !important;
}

/*
 * Text colors
 */
.color-primary {
  color: @brand-primary;
  .a-coloring(@brand-primary);
}
.color-secondary {
  color: @brand-secondary;
  .a-coloring(@brand-secondary);
}
.text-success,
.color-success {
  color: @brand-success;
  .a-coloring(@brand-success);
}
.text-warning,
.color-warning {
  color: @brand-warning;
  .a-coloring(@brand-warning);
}
.text-error,
.text-danger,
.color-error,
.color-danger {
  color: @brand-danger;
  .a-coloring(@brand-danger);

}
.text-info,
.color-info {
  color: @brand-info;
  .a-coloring(@brand-info);
}

/*
 * Bg colors
 */
.bg-primary {
  background: @brand-primary;
}
.bg-secondary {
  background: @brand-secondary;
}
.bg-success {
  background: @brand-success;
}
.bg-warning,
.background-warning {
  background: @brand-warning;
}
.bg-error,
.bg-danger {
  background: @brand-danger;
}
.bg-info {
  background: @brand-info;
}

/*
 * Number colors
 */
.negative {
  color: @brand-danger;
}
.zero {
  color: @gray-medium;
}

svg {
  .negative {
    fill: @brand-danger;
  }
  .zero {
    fill: @gray-medium;
  }
}


/*
 * individual styles
 */
.link-unconfirmed-transactions {
  text-align: left!important;
  .wrap-normal();
  .badge-unconfirmed {
    .pull-right();
  }
  margin-bottom: @gutter;
  padding-left: @gutter;
  padding-right: @gutter;
}
