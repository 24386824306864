table.messages-list {
  tr {
    &.unread {
      font-weight: 700;
      .important-mark {
        .text-danger();
      }
    }

    &.read {
      color: lighten(@gray,15%);
    }
  }
}
