table.import {
  tr.heading-col-names {
    td {
      text-align: center!important;
      color: @gray;
      padding: 0;
    }
  }

  > tbody {
    > tr {
      > td {
        border-top: none;
        vertical-align: middle;

        table {
          td {
            border: none;
            padding: 0;
          }
        }
      }

      &.instrument_footer {
        td {
          padding-top: @gutter;
          padding-bottom: @gutter;
        }
      }
      &.instrument_header.first_header {
        td {
          padding-top: @gutter;
        }
      }
      &.deleted > td,
      td.deleted {
        background: @gray-light;
      }
    }

    > tr.valid {
      background: lighten(@brand-success, 40%);
    }
  }
}
