// These override the main daterangepicker.css file (which is manually dragged in from the Git repo).
.daterangepicker {
  .range_inputs {
    font-size: 0; // get rid of inline-block whitespace

    .applyBtn {
      .btn-secondary();
    }

    .cancelBtn {
      margin-left: 5%;
    }

    .applyBtn,
    .cancelBtn {
      width: 47.5%;
      font-size: 13px;
      line-height: 26px;
      padding: 0;

      &.hide { // don't hide with autoApply (since you can still edit the inputs...)
        display: inline-block !important;
      }
    }
  }

  .calendar {
    @media (max-width: 730px) { // spacing on mobile; max-width matches daterangepicker.css
      margin-top: 30px;
    }
  }

  td.in-range:not(.active) { // support for ie9+
    background: @daterangepicker-active-soft-bg;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  td.active,
  td.active:hover {
    background-color: @daterangepicker-active-bg;
    border-color: @daterangepicker-active-bg;
    color: @daterangepicker-active-text;
  }

  td.start-date {
    -webkit-border-radius: 4px 0 0 0;
    -moz-border-radius: 4px 0 0 0;
    border-radius: 4px 0 0 0;
  }

  td.end-date {
    -webkit-border-radius: 0 0 4px 0;
    -moz-border-radius: 0 0 4px 0;
    border-radius: 0 0 4px 0;
  }

  td.end-date,
  td.start-date {
    border-color: transparent; // they have a radius on them and tables don't adhere to radius
  }

  .ranges {
    ul li {
      font-size: @font-size-md;
      background: @gray-lighter;
      border: 1px solid @gray-lighter;
      color: @text-color;

      &.active,
      &:hover {
        background: @daterangepicker-active-bg;
        border: 1px solid @daterangepicker-active-bg;
        color: @daterangepicker-active-text;
      }
    }
  } // .ranges

}
