div.environment-banner {
  background-color: @brand-info;
  color: @cream;
  font-weight: bold;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 20px;
  z-index: 9999;

  &.development {
    background-color: @brand-warning;
    color: black;
  }
  &.edge {
    background-color: yellow;
    color: black;
  }
  &.production {
    background-color: @brand-danger;
  }
  &.staging {
    background-color: @brand-success;
  }
  &.testing {
    background-color: @brand-primary;
  }
}

div.eb-padding {
  height: 20px;
}
