// The Google Button uses Roboto -- this special `(css)` syntax tells LESS to not try to actually connect to HTTPS and instead pass this directly down to css.
@import (css) url(https://fonts.googleapis.com/css?family=Roboto);

@__button-height: 40px;
@__google-blue: #4285f4;

.material-button {
  display: block;
  width: auto; // this is 100% on buttons/inputs
  max-width: 215px;
  padding: 0;
  margin: 0 auto @gutter;
  border: 1px solid #fff;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  box-sizing: border-box;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  button&, input& {
    width: 100%;
  }

  @media (max-width: @screen-phone) {
    max-width: 100%;
  }

  // for the font text
  &, &__text {
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    line-height: @__button-height - 2px; // -2px from border
    font-weight: 500;
    letter-spacing: .21px;
  }

  &[disabled] {
    filter: grayscale(100%);
    cursor: not-allowed;
    pointer-events: none;
  }

  &, &:hover {
    text-decoration: none;
    color: #262626 !important;
  }

  &:hover {
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, .3); // match background-color
    color: #262626;
  }

  &--inline {
    margin: 0;
    display: inline-block;
  }

  &--google {
    border: 1px solid @__google-blue;
    background-color: @__google-blue;

    &, &:hover {
      color: #fff !important; // NOTE: Important because layouts use a bad specificity trump with #page.
    }

    &:hover {
      box-shadow: 0 0 3px 3px fade(@__google-blue, .25); // aka rgba
    }
  }

  &--primary {
    border: 1px solid @brand-primary;
    background-color: @brand-primary;

    &, &:hover {
      color: #fff !important; // NOTE: Important because layouts use a bad specificity trump with #page.
    }

    &:hover {
      box-shadow: 0 0 3px 3px fade(@brand-primary, .25); // aka rgba
    }
  }

  &__icon {
    display: block;
    float: left;
    border-radius: 1px;
    background-color: #fff;
    line-height: 0; // so we don't get unnecessary image block height
    padding: (@__button-height - 18px)/2;

    img {
      vertical-align: top;
      width: 18px;
      height: 18px;
    }
  }

  &__inset {
    display: inline;
    margin-left: 12px;
    margin-right: 12px;
    color: inherit;
  }

  // to center the button on mobile
  @media (max-width: @screen-phone) {
    &__icon + &__text {
      margin-left: -@__button-height/2;
      margin-right: 12px;
    }
  }
}
