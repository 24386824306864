// See: http://browser-update.org/customize.html
// Styling what we render in `app/assets/javascripts/app/unsupported_browser.js`

// Just minor revisions to clean it up for Sharesight.

body {
  .buorg {
    animation-duration: 10ms; // slide in from the top, fast
    box-shadow: none;
    border-bottom: 2px solid @gray-dark;
    background: @gray-lighter;
    color: @text-color;
  }

  .buorg-fadeout {
    transition: visibility 0ms 1s, opacity 1s ease-out 250ms;
  }

  .buorg-pad {
    padding: @gutter;
  }

  .buorg-buttons {
    margin-top: @gutter/3;
  }

  #buorgig, #buorgul, #buorgpermanent {
    color: @btn-primary-color;
    box-shadow: none;
    background: @btn-primary-bg;
  }

  #buorgig {
    color: @btn-default-color;
    background: @btn-default-bg;
  }
}
