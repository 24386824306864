#trades__trade_form {

  @media (max-width: @screen-phone) {
    .field.attachments {
      display: none;
    }
  }
}


