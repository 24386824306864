/* FONT PATH
 * -------------------------- */

// NOTE: These fonts are modified with a direct aliased url instead.
@font-face {
  font-family: 'FontAwesome';
  src: url('~fonts/fontawesome-webfont.eot');
  src: url('~fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
    url('~fonts/fontawesome-webfont.woff') format('woff'),
    url('~fonts/fontawesome-webfont.ttf') format('truetype'),
    url('~fonts/fontawesome-webfont.svg#fontawesomeregular') format('svg');
  // src: url('~fonts/FontAwesome.otf') format('opentype'); // NOTE: Previously used when developing fonts…?  Unsure what that meant.
  font-weight: normal;
  font-style: normal;
}
