@media print {

  html,
  body {
    height: auto!important;
  }

  .header-area {
    display: none;
  }

  #show_holding .rightbar {
    display: none;
  }

  a[href]:after,
  .criteria-bar,
  .summaries,
  .btn,
  .no-print {
    display: none;
  }

  .box,
  .page-wrapper.box {
    padding: 0;
    border: none;
  }

  .td-holding-name {
    white-space: normal!important;
  }
}

// Print and PDF page break options:
.page-break-afterwards {
  clear: both;
  display: block;
  border: 1px solid transparent;
  page-break-after: always;
}
.page-break-avoid-inside {
  page-break-inside: avoid;
}
