table.table-performance-contributions {
  &,
  &.spreadsheet {
    // we want a fixed table layout here (same-sized columns)
    // `.spreadsheet` is for increased specificity
    table-layout: fixed;
  }

  td, th {
    &:first-child {
      width: 300px;
    }
  }
}
