
#xero_settings__form {

  table.xero-connect {
    width: 100%;

    > tbody > tr {
      &:nth-of-type(even) > td {
        background: @gray-lighter;
      }

      > td {
        padding: (@gutter/2);
        &:nth-of-type(1) { width: 50%; }
        &:nth-of-type(2) { width: 10%; }
        &:nth-of-type(3) { width: 40%; }
      }
    }

  }
}
