th.td-hightlighted {
  background: darken(@table-head-bg, 5%)!important;
}
td.td-hightlighted {
  background: @table-head-bg !important;
}
tr:hover {
  > td.td-hightlighted {
    background: darken(@table-bg-hover,5%)!important;
  }
}
