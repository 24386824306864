#plans_create_payment {
  .payment-info {
    .flashmessage();
    .alert-variant(@alerts-info-border);
    padding: @gutter;
    //padding-right: 40px;

    &.payment-info-flag {
      margin-right: 20px;
      &::after {
        position: absolute;
        top: @gutter;
        right: 15px;
        display: inline-block;
        border-bottom: 32px solid transparent;
        border-left: 20px solid @alert-info-border;
        border-top: 32px solid transparent;
        content: '';
      }
    }

  }
  .invoice {
    .invoiceheader {
      border-bottom: none !important;
    }

    .thanks {
      padding-top: 0;
    }
  }
} // end #plans_create_payment


#plans__index_pro {

  ul.plans {
    .list-unstyled();
    display: table;
    width: 100%;

    > li {
      padding-right: (@gutter/2);
      padding-left: (@gutter/2);
      display: table-cell;
      width: 26%;
      text-align: center;

      &:last-child {
        padding-right: 0;
        width: 24%;
      }

      &:first-child {
        padding-left: 0;
        width: 24%;
      }

      label {
        width: 100%;
        height: 100%;
        padding: 0 @gutter;
        border: 1px solid @gray-light;
        border-radius: @border-radius-small;

        .header {
          padding: @gutter 0;
        }
        .body {
          padding: 0 0 @gutter;

          .price {
            .h3();
          }
        }
      }

      &.selected label {
        background-color: @body-bg;
      }
    }
  }

  .case-selected {
    text-align: center;

    .total-desc {
      margin-bottom: 0;

      .hint.portfolio-difference {
        margin-right: -150px;
      }
    }

    .hint {
      .dull();
      font-size: @font-size-md;

      &.portfolio-difference {
        display: inline-block;
        margin-left: @gutter;
        padding: (@gutter/2);
        background: @gray-lighter;
        height: 35px;
        width: 150px;

        .inner {
          padding-top: 3px;
          padding-bottom: 3px;
          position: relative;

          &::before {
            position: absolute;
            top: -7px;
            left: -17px;
            display: inline-block;
            border-bottom: 17px solid transparent;
            border-right: 10px solid @gray-lighter;
            border-top: 17px solid transparent;
            content: '';
          }
        }
      }

    }
  }

  .actions > div {
    border: 1px solid @gray-light;
    .border-top-radius(@border-radius-small);
    border-bottom: none;
    padding: @gutter;
  }

  .description {
    > ul {
      .list-unstyled();
      > li {
        line-height: (@line-height-base*1.3);

        &:before {
          .make-font-awesome();
          .font-awesome-fw();
          color: @brand-success;
          content: @fa-var-check;
          font-size: 1.3em;
        }
      }

      > li.off {
        color: @gray-light;
        &:before {
          content: '';
        }
      }
    }
  }
} // end #plans__index_pro

#plans__index_standard {
  .flag-trial {
    .small();
    .badge();
    background: @brand-success;
    color: #fff !important;
  }


  // For all Add-Ons
  .pricing-container {
    border-left: 1px solid @gray-light;
    padding-left: (@gutter/2);

    .price-label {
      color: white;
      font-size: @font-size-xxl;
      &.per {
        font-size: @font-size-md;
      }
    }
  }

  // For Xero Cashbook Add-On only:
  .addon-banner.xero-cashbook {
    @xero-brand-color: #00A2D5;
    background: @xero-brand-color;
    color: white;

    .pricing-container {
      border-left: 1px solid darken(@xero-brand-color, 5%);
    }
    .price-label,
    a:not(.btn),
    a:not(.btn):visited,
    > p,
    form h3 {
      color: white;
    }
  }
} // end #plans__index_standard

#plans__plan_details {

  .price-label {
    margin: 0;
    small {
      color: @gray;
      font-size: @font-size-md;
    }
  }

  .group-heading {
    > td {
      font-style: bold;
    }

    &:hover > td {
      cursor: pointer;
      background: @gray-lighter;
      &.selected {
        border-color: transparent;
        background: darken(@table-selected-bg,5%);
      }
    }
  }

  .group-switch-ico {
    width: 12px;
  }
  .group-heading.open > td {
    background: @gray-lighter;
    &.selected {
      border-color: transparent;
      background: darken(@table-selected-bg,5%);
    }
  }

  .group-body {
    > tr > td {
      background: @gray-lighter;
      &.selected {
        border-color: transparent;
        background: darken(@table-selected-bg,5%);
      }
      &:last-child {
        border-bottom: none;
      }
    }
    > tr.group-empty > td {
      height: @gutter;
      background: transparent;
    }
  }
}
