@import "table_td_hightlighted";
@import "table_performance_contributions";

.td-left {
  text-align: left !important;
  // FIXME: text-align center has been set somewhere too soon
  // Should not need to add important to make this work
  &.header { .show-sortable-left(); }
}

.td-center {
  text-align: center;
  &.header { .show-sortable-left(); }
}

.td-right {
  text-align: right;
  &.header { .show-sortable-right(); }
}

// Trade table
.data-table-trades {
  th {
    padding-left:3px !important;

    &:first-child {
      padding-left:16px !important;
    }
  }

  .td-col-2-trade {width:61px;}
  .td-col-3-trade {width:69px;}
  .td-col-4-trade {width:79px}
  .td-col-5-trade {width:67px}
  .td-col-6-trade {width:89px}
  .td-col-trade-currency {width:79px}
  .td-col-trade-exch {width:89px}
  .td-col-trade-val {width:79px}
}

@media (min-width: @screen-xl-desktop) {
  .data-table-trades {
    th {
      padding-left:3px !important; // FIXME: padding left is set too early somewhere

      &:first-child {
        padding-left:16px !important;
      }
    }

    .td-col-2-trade {width:100px;}
    .td-col-3-trade {width:109px;}
    .td-col-4-trade {width:79px}
    .td-col-5-trade {width:117px}
    .td-col-6-trade {width:99px}
    .td-col-trade-currency {width:79px}
    .td-col-trade-exch {width:89px}
    .td-col-trade-val {width:130px}
  }
}

.td-holding-name {
  .holding-name {
    @media screen and (max-width: @screen-desktop) {
      display: none;
    }
  }

  .holding-naming {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: @screen-desktop) {
      width: 340px; // Same width as `.td-col-1`
    }
  }
}

.holding-name-limit-width {
  width: 26rem; // approx 416px
}

.date-limit-width {
  width: 12rem; // room for date
}

.td-col-1 {
  width: 103px;
}

@media screen and (min-width: @screen-tablet) {
  .td-col-1 {display: inline-block; width: 140px;}
  .td-col-1, .td-col-2, .td-col-3, .td-col-4, .td-col-5, .td-col-6, .td-col-7,
  .td-col-8 {
    padding-left: 0;
    padding-right: 16px;
   }
  .td-col-add {width: 84px;}
  .td-col-2 {width: 90px;}
  .td-col-3 {width: 80px;}
  .td-col-4 {width: 80px;}
  .td-col-5 {width: 80px;}
  .td-col-6 {width: 80px;}
  .td-col-7 {width: 80px;}
  .td-col-8 {width: 80px;}
  .hrt-col-8 {width:60px;}
}

@media print, (min-width: @screen-desktop) {
  .td-col-1 {
    display: inline-block;
    width: 340px;
  }

  .td-col-add {width: 132px;}
  .td-col-2 {width: 130px}
  .td-col-3 {width: 93px}
  .td-col-4 {width: 110px}
  .td-col-5 {width: 147px}
  .td-col-6 {width: 122px}
  .td-col-7 {width: 146px}
  .td-col-8 {width: 119px}
  .hrt-col-8 {width:64px;}

  // the toggle in first cell
  .switch-to-percent {
    margin-left: 5px;
  }

  table.spreadsheet thead > tr > th {
    padding-left: 4px;
    padding-right: 4px;
  }
}


@media screen and (min-width: @screen-xl-desktop) {
  .td-col-1 {display: inline-block; width: 340px;}
  .td-col-add {width: 84px;}
  .td-col-2 {width: 122px;}
  .td-col-3 {width: 86px;}
  .td-col-4 {width: 103px;}
  .td-col-5 {width: 120px;}
  .td-col-6 {width: 100px;}
  .td-col-7 {width: 115px;}
  .td-col-8 {width: 114px;}
  .hrt-col-8 {width:60px;}

  table.spreadsheet thead > tr > th {
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media screen and (min-width: @screen-tablet) {
  .perf-td-1 {width: 140px;}
  .perf-td-2 {width: 90px;}
  .perf-td-3 {width: 80px;}
  .perf-td-4 {width: 80px;}
  .perf-td-5 {width: 80px;}
  .perf-td-6 {width: 80px;}
  .perf-td-7 {width: 80px;}
  .perf-td-8 {width: 80px;}
}

@media print, (min-width: @screen-desktop) {
  .perf-td-1 {width: 340px;}
  .perf-td-2 {width: 130px;}
  .perf-td-3 {width: 93px;}
  .perf-td-4 {width: 110px;}
  .perf-td-5 {width: 147px;}
  .perf-td-6 {width: 122px;}
  .perf-td-7 {width: 146px;}
  .perf-td-8 {width: 119px;}
}

table.spreadsheet {
  table-layout: auto;
  margin-bottom: (@gutter*2.5);

  > tbody > tr > td,
  > thead > tr > th,
  > tfoot > tr > td {
    .td-right();
    &:first-child {
      .td-left();
    }
  }
}

table.spreadsheet.spreadsheet-loose {
  table-layout: auto;
}

table.grand_total {
  &:extend(.table all);
  border-top: 3px double @table-border-color;
  background-color: lighten(@table-head-bg,40%);
  //font-size: ceil(@font-size-base * 1.25);
  //.h4();
  font-weight: 700;

  thead > tr {
    th.header,
    td.header {
      //.show-sortable-left();
    }
  }

  thead > tr > th {
    border-bottom: none;
    padding-bottom: 0;
    background: transparent;
  }
  tbody > tr > td {
    border-top: none;
  }
  tbody > tr:last-child > td {
    border-bottom: 1px solid @table-border-color;
  }

  > tbody > tr > td,
  > thead > tr > th,
  > tfoot > tr > td {
    .td-right();


  }
}

table.balancesheet {
  .page-break-avoid-inside(); /* no inline page-breaks in prints and PDFs */

  > tbody > tr > td,
  > thead > tr > th,
  > tfoot > tr > td {
    white-space: nowrap;
    @media (max-width: @screen-phone) {
      white-space: unset;
    }
    .td-right();


    &:nth-child(1) { .td-left(); }
    &.total,
    &.subtotal {
      border-top: 3px double @text-color;
    }

    &.subtotal_underline {
      border-bottom: 1px solid @text-color;
    }

    &.total_underline {
      border-top: 1px solid @text-color;
      border-bottom: 3px double @text-color;
    }

    .operator {
      float: left;
      margin-right: (@gutter/2);
    }
  }

  .col1 {
    min-width: 24rem;
  }

  .col2 {
    min-width: 8rem;
  }

  .col3 {
    padding-left: 8px;
  }
}
/*
 * Historical costs PDF styles
 */
table.spreadsheet.pdf_landscape {
 table-layout: auto;

 .pdf-hist-1 {
   width: 398px;
 }
}

/*
 * sold securties
 */
 .pdf_landscape {
   .sale-value {width:80px;}
   .dividends {width:80px;}
   .total-return {width:74px;}
 }

/*
 * Plan tables
 */
.plan-table {
  .table();
  .text-center();

  th {
    /* override th */
    .text-center();
  }

  tbody + tbody {
    border-top-width: 1px;
  }
  tbody tr td {
    vertical-align: middle;

    h1,h2,h3,h4,h5,h6,p {
      margin: 0;
    }
  }

  .badge {
    background: @brand-success;
  }

  i.ico-check,
  i.ico-minus {
    color: @brand-success;
  }
}

/*
 * Dashboard Pro Table
 */
table.table-dashboard {
  > thead > tr > th {
    background-color: @table-dashboard-head-bg;
    color: @table-dashboard-color;
  }

  > tbody > tr > td {
    background-color: @table-dashboard-bg;
    > table {
      background: white;
    }
  }

  > tbody > tr.no-results > td {
    background: white !important;
  }
}

table thead th.third {
  width: 33%!important;
}

// Trumps
.td-align-left {
  text-align: left !important;
}
