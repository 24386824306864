//
// Responsive: Utility classes
// --------------------------------------------------


// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
//
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
  width: device-width;
}

// IE10 on Windows Phone 8
// IE10 on WP8 doesn't report CSS pixels, but actual device pixels. In
// other words, say on a Lumia, you'll get 768px as the device width,
// meaning users will see the tablet styles and not phone styles.
//
// Alternatively you can override this with JS (see source below), but
// we won't be doing that here given our limited scope.
//
// Source: http://timkadlec.com/2013/01/windows-phone-8-and-device-width/
@media screen and (max-width: 400px) {
  @-ms-viewport{
    width: 320px;
  }
}

// Hide from screenreaders and browsers
// Credit: HTML5 Boilerplate
.hidden {
  display: none !important;
  visibility: hidden !important;
}

// Visibility utilities

.visible-xs {
  .responsive-invisibility();
  @media (max-width: @screen-xs-max) {
    .responsive-visibility();
  }
  &.visible-sm {
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
      .responsive-visibility();
    }
  }
  &.visible-md {
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
      .responsive-visibility();
    }    
  }
  &.visible-lg {
    @media (min-width: @screen-lg) {
      .responsive-visibility();
    }    
  }
}
.visible-sm {
  .responsive-invisibility();
  &.visible-xs {
    @media (max-width: @screen-xs-max) {
      .responsive-visibility();
    }    
  }
  @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
    .responsive-visibility();
  }
  &.visible-md {
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
      .responsive-visibility();
    }    
  }
  &.visible-lg {
    @media (min-width: @screen-lg) {
      .responsive-visibility();
    }    
  }
}
.visible-md {
  .responsive-invisibility();
  &.visible-xs {
    @media (max-width: @screen-xs-max) {
      .responsive-visibility();
    }    
  }
  &.visible-sm {
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
      .responsive-visibility();
    }
  }
  @media (min-width: @screen-md) and (max-width: @screen-md-max) {
    .responsive-visibility();
  }
  &.visible-lg {
    @media (min-width: @screen-lg) {
      .responsive-visibility();
    }    
  }
}
.visible-lg {
  .responsive-invisibility();
  &.visible-xs {
    @media (max-width: @screen-xs-max) {
      .responsive-visibility();
    }    
  }
  &.visible-sm {
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
      .responsive-visibility();
    }
  }
  &.visible-md {
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
      .responsive-visibility();
    }    
  }
  @media (min-width: @screen-lg) {
    .responsive-visibility();
  }
}

.hidden-xs {
  .responsive-visibility();
  @media (max-width: @screen-xs-max) {
    .responsive-invisibility();
  }
  &.hidden-sm {
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
      .responsive-invisibility();
    }    
  }
  &.hidden-md {
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
      .responsive-invisibility();
    }    
  }
  &.hidden-lg {
    @media (min-width: @screen-lg) {
      .responsive-invisibility();
    }    
  }
}
.hidden-sm {
  .responsive-visibility();
  &.hidden-xs {
    @media (max-width: @screen-xs-max) {
      .responsive-invisibility();
    }
  }
  @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
    .responsive-invisibility();
  }
  &.hidden-md {
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
      .responsive-invisibility();
    }    
  }
  &.hidden-lg {
    @media (min-width: @screen-lg) {
      .responsive-invisibility();
    }    
  }
}
.hidden-md {
  .responsive-visibility();
  &.hidden-xs {
    @media (max-width: @screen-xs-max) {
      .responsive-invisibility();
    }    
  }
  &.hidden-sm {
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
      .responsive-invisibility();
    }    
  }
  @media (min-width: @screen-md) and (max-width: @screen-md-max) {
    .responsive-invisibility();
  }
  &.hidden-lg {
    @media (min-width: @screen-lg) {
      .responsive-invisibility();
    }    
  }
}
.hidden-lg {
  .responsive-visibility();
  &.hidden-xs {
    @media (max-width: @screen-xs-max) {
      .responsive-invisibility();
    }    
  }
  &.hidden-sm {
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
      .responsive-invisibility();
    }    
  }
  &.hidden-md {
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
      .responsive-invisibility();
    }    
  }
  @media (min-width: @screen-lg) {
    .responsive-invisibility();
  }
}

// Print utilities
.visible-print {
  .responsive-invisibility();
}

@media print {
  .visible-print {
    .responsive-visibility();
  }
  .hidden-print {
    .responsive-invisibility();
  }
}
