/*
 * Forms
 */

// Remove button focus border
.btn:focus {
  outline: 0 !important;
}

.input-lg {
  // same as original bootstrap, but with NO large border radius!
  .input-size(@input-height-large; @padding-large-vertical; @padding-large-horizontal; @font-size-lg; @line-height-large; @border-radius-base);
}

.form-control-inline {
  .form-control();
  width: auto !important;
  display: inline-block !important;
}

// Better position radios and checkboxes
input[type="radio"],
input[type="checkbox"] {
  margin: 2px 0 0;
  margin-top: 2px \9; /* IE8-9 */
  line-height: normal;
}

.input-group-addon {
  > i.ico {
    /* same as font awesome .fa-fw */
    width: (18em / 14);
    text-align: center;
    /* additional: */
    margin-left: -6px;
    margin-right: -7px;
  }
}

.input-without-focus {
  // revert .form-control-focus() effect:
  &:focus {
    border-color: @input-border;
    .box-shadow(~"none");
  }
}

.field,
.fields {
  &:extend(.form-group all);
}

.field-extra-margin {
  margin-bottom: @space-6;
}

.actions {
  margin-top: @gutter;
}

.actions-divider {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  .text {
    margin: 0 @gutter;
  }
  hr {
    flex-grow: 1;
  }
}

.admin_buttons {
  width: 100%;
  display: inline-block;
  position: sticky;
  top: 0;
  padding: @gutter/2 @gutter;
  margin-bottom: 5px;
  z-index: 200;

  background: white;
  border: 1px solid @gray-light;
}

.form-horizontal {
  .form-group {
    margin-left: 0;
    margin-right: 0;
  }
}

.label-bottom-overflow {
  display: block;
  height: 18px;
  margin-bottom: -18px;
  margin-left: -@gutter;
  margin-right: -@gutter;
}

/* input-lg only above iphone size */
.input-lg {
  padding-left: (@gutter/2);
  padding-right: (@gutter/2);

  @media (max-width: @screen-phone) {
    height: @input-height-base;
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-md;
    line-height: @line-height-base;
    border-radius: @border-radius-base;

    select& {
      height: @input-height-base;
      line-height: @input-height-base;
    }

    textarea& {
      height: auto;
    }
  }
}

/*
 * Horizontal Fields
 */
.horizontal-fields {
  margin-bottom: 3em;
  display: table;
  width: 100%;

  .field {
    display: table-cell;
    vertical-align: bottom;
    text-align: center;

      .value {
        text-align: center;
        vertical-align: middle;
        padding-top: (@gutter/2);
        padding-bottom: (@gutter/2);
        border-radius: @border-radius-base;

      }

    &.symbol {
      .h1();
      color: @gray;
      padding-left: (@gutter/3);
      padding-right: (@gutter/3);

      > span {
        display: block;
        padding-top: (@gutter*1.5);
        padding-bottom: (@gutter/3);

        @media (max-width: @screen-phone) {
          padding-bottom: 0;
        }
      }

      &.symbol.symbol-lg {
        > span {
          padding-bottom: (@gutter/2);
        }
      }
    }

    &.share_price {
      min-width: 6em;
    }

    &.total-cell {
      min-width: 100px;
      vertical-align: bottom;

      &.total-cell-lg {
        .value {
          .h2();
          height: 43px;
        }
      }

      .value {
        min-width: 100px;
        display: table-cell; /* cheat to get in responsive fitting */
      }
    }

    input[type="text"],
    input[type="number"] {
      .text-center();
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  @media (max-width: @screen-phone) {
    display: block;
    .make-row();
    .field {
      text-align: left;
      float: left;

      input[type="text"],
      input[type="number"] {
        text-align: left;
      }
    }

    .field.symbol {
      .make-xs-column(1);
      padding: 0;
    }
    .field:not(.symbol) {
      .make-xs-column(11);
    }

    .label-bottom-overflow {
      display: block;
      margin: 0;
      height: auto;
    }

    .field.total-cell {
      .make-xs-column(12);
      padding-right: 0;
      float: right;
      text-align: right !important;

      label, .value {
        .h3();
        font-size: @font-size-lg !important;
        vertical-align: top;
        height: auto;
        margin: 0;
        padding: 0 !important;
        display: inline !important;
        text-align: right !important;
      }
    }
  }
}

form {
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="date"],
  input[type="tel"],
  textarea {
    &:extend(.form-control all);

    &[disabled],
    &[readonly],
    fieldset[disabled] & {
      cursor: not-allowed;
      background-color: @input-bg-disabled;
    }
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="email"] {
    &.input-sm {
      .input-sm();
    }
    &.input-lg {
      .input-lg();
    }
  }

  textarea {
    height: auto;
  }
}

/* Targets all inputs with type=number to disable the increment/decrement arrows */
/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// Reset rounded corners
// -------------------------
.input-group {
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  textarea,
  .form-control {
    &:last-child {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
  }
}

/*
 * Radio button styles
 */
.radio-btn {
  .radio-inline();

  margin-left: 0;
  margin-right: -4px;
  padding-right: 12px;
  padding-left: 10px;

  padding-top: @padding-small-vertical;
  padding-bottom: @padding-small-vertical;
  border-radius: @border-radius-base;

  &.active {
    background: @gray-lighter;
    color: @gray-dark;
  }
}


/*
 * Error handling
 */
.field_with_errors {
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  select {
    @color: @brand-danger;
    @color-rgba: rgba(red(@color), green(@color), blue(@color), .6);
    border-color: @color-rgba;
    outline: 0;

    &:focus {
      border-color: @color;
    }
  }
}


/*
 * Datepicker jQuery UI
 */
input.datepicker {
  .text-center();
}

.datepicker-fields {
  .text-center();
}

.input-group .datepicker {
  text-indent: -@gutter*2;
}

.ui-datepicker {
  @datepicker-border: darken(@gray-light, 7%);

  width: 216px;
  height: auto;
  margin: 5px auto 0;
  .box-shadow(0px 0px 3px 0px @gray-dark);
  background: @gray-lighter;
  border-radius: @border-radius-base;


  a {
    text-decoration: none;
    color: @gray-lighter;
  }
  table {
    width: 100%;
  }

  thead {
    .make-gradient-vertical(white, white, @gray-lighter);
    border-bottom: 1px solid @gray-light;
  }

  th {
    text-align: center;
    font-size: 6pt;
    padding: 5px 0;
    color: @gray;
    text-shadow: 1px 0px 0px white;
  }

  tbody td {
    padding: 0;
    border-right: 1px solid @datepicker-border;
  }

  tbody td:last-child {
    border-right: 0px;
  }

  tbody tr {
    border-bottom: 1px solid @datepicker-border;
  }

  tbody tr:last-child {
    border-bottom: 0px;
  }

  td span,
  td a {
    display: inline-block;
    font-weight: bold;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #666666;
    text-shadow: 1px 1px 0px #fff;
    filter: dropshadow(color=#fff, offx=1, offy=1);
  }


  .ui-datepicker-header {
    background: @gray;
    color: @gray-lighter;
    font-weight: bold;
    .box-shadow(inset 0px 1px 1px 0px rgba(250, 250, 250, 0.2));
    .border-top-radius(@border-radius-base);
    text-shadow: 0 1px 0px rgba(0,0,0,0.3);
    padding: (@gutter/2);
    border-top: 1px solid @gray-dark;
  }

  .ui-datepicker-title {
    text-align: center;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    display: inline-block;
    width: 30px;
    margin: -(@gutter/2);
    padding: (@gutter/2);
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    text-indent: -999px;

    .make-font-awesome();
  }

  .ui-datepicker-prev {
    float: left;
    &:before {
      position: absolute;
      left: 7px;
      text-indent: 0;
      font-size: 1.25em;
      content: @fa-var-chevron-left;
    }

    &:hover:before {
      color: @brand-primary;
    }
  }

  .ui-datepicker-next {
    float: right;
    &:before {
      position: absolute;
      right: 7px;
      text-indent: 0;
      font-size: 1.25em;
      content: @fa-var-chevron-right;
    }

    &:hover:before {
      color: @brand-primary;
    }
  }

  .ui-datepicker-calendar {
    .ui-state-default {
      .make-gradient-vertical(@gray-lighter, @gray-light, @gray-lighter);
      .box-shadow(inset 1px 1px 0px 0px rgba(255,255,255,0.3));
    }

    .ui-datepicker-week-end {
      .ui-state-default {
        .make-gradient-vertical(@gray-light, darken(@gray-light,10%), @gray-light);
      }
    }

    .ui-state-highlight {
      background: white;
    }

    .ui-state-hover {
      background: @gray-lighter !important;
      color: black;
    }

    .ui-state-active {
      background: @brand-primary !important;
      .box-shadow(inset 0px 0px 10px 0px darken(@brand-primary, 10%));
      color: @gray-lighter;
      text-shadow: 0px 1px 0px darken(@brand-primary, 15%);
      border: 1px solid darken(@brand-primary, 15%);
      position: relative;
      margin-right: -1px !important;
    }

    td:first-child .ui-state-active {
      width: 29px;
      margin-left: 0;
    }

    td:last-child .ui-state-active {
      width: 29px;
      margin-right: 0;
    }

    tr:last-child .ui-state-active {
      height: 29px;
      margin-bottom: 0;
    }

  }
  .ui-datepicker-unselectable .ui-state-default {
    background: @gray-light;
    color: @gray;
  }

  .ui-datepicker-buttonpane {
    border-top: 1px solid @datepicker-border;
    button {
      .btn();
      .btn-default();
      .btn-sm();

      &.ui-datepicker-current {
        float: left;
        border-left: none;
        border-top: none;
        border-bottom: none;
        border-bottom-left-radius: @border-radius-base;
      }
      &.ui-datepicker-close {
        float: right;
        border-right: none;
        border-top: none;
        border-bottom: none;
        border-bottom-right-radius: @border-radius-base;
      }
    }
  }
}

/*
 * jqueryi UI autocomplete form
 * http://jqueryui.com/autocomplete/
 */
.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
}

// hidden elements
.ui-helper-hidden-accessible { display: none; }

// input
.ui-autocomplete-input {
  background-image: url('~images/spinner_16x16.gif');
  background-repeat: no-repeat;
  background-position: -999px center;

  &.ui-autocomplete-loading {
    background-position: right @gutter center;
  }
}

// list element
.ui-autocomplete {
  .nav();
  .nav-pills();
  .nav-stacked();
  width: auto;
  background: @input-bg;
  .box-shadow(0 0 60px rgba(0,0,0,.2));
  .border-bottom-radius(@input-border-radius);
  border: 1px solid @gray-light;
  z-index: @zindex-ui-autocomplete;

  > li {
    > a {
      .border-top-radius(@input-border-radius);
      .border-bottom-radius(@input-border-radius);
      color: @dropdown-link-color;

      &.ui-state-hover,
      &.ui-state-focus,
      &:hover,
      &:focus {
        color: @autocomplete-hover-color;
        background: @autocomplete-hover-bg;
        cursor: pointer;
      }
    }
  }
  > li:first-child > a {
    .border-top-radius(0);
  }

  .divider {
    height: 1px;
    margin: 9px 1px;
    overflow: hidden;
    border-bottom: 1px solid @gray-light;
  }
}

// invisible form elements (robot prevention)
input#user-comment {
  position: absolute;
  left: -10000px;
}

.professional-edit-title {
  text-align: center;
  display: block;
  margin-bottom: @gutter;

  @media (min-width: @screen-tablet) {
    margin: 0 30px 0 0;
    float: left;
    vertical-align: middle;
    line-height: 42px; // match input's full height
  }
}
