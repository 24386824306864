.header-area {
  position: relative;
  background: @header-bg;

  .container.header {
    padding-left: 0;
    padding-right: 0;

    @media(max-width: @screen-desktop) {
      padding-left: (@gutter/2);
      padding-right: (@gutter/2);
    }
  }
}

#header {
  .btn-default {
    .button-variant(@header-tabs; @gray-darker);
    .box-shadow(none);
    background: @gray-darker;
    filter: none;
    &:hover,
    &:focus,
    &.hover,
    &:active,
    &.active,
    .open .dropdown-toggle& {
      background: @gray-darker;
      filter: none;
      border-color: @header-bg;
    }
  }

  .btn .caret {
    border-top-color: @gray-light;
  }

  #logo {
    float: left;
    margin: 0 0 5px;

    // Centered on mobile
    @media (max-width: @screen-phone) {
      float: none;
      margin: 0 auto 5px;
    }

    img {
      width: auto;
      max-height: 63px;
      display: block;

      // Centered on mobile
      @media (max-width: @screen-phone) {
        margin-left: auto;
        margin-right: auto;
      }
    }

  }

  nav.account-menu {
    float: right;
    margin-top: 15px;

    text-align: right;
    > * {
      text-align: left;
    }

    @media (max-width: @screen-phone) {
      margin-top: 0;
      margin-bottom: 5px;
    }

    #logout_panel {
      float: right;
    }
  }
}
