/*
 **************
 * Table styles
 **************
 */
tfoot {
   display: table-row-group; // We don't want tfoot to stick to the bottom of the page when printed.
}

.table {
  border-bottom: 3px double @table-border-color;

  > thead > tr > th {
    background-color: @table-head-bg;
    font-weight: @table-head-font-weight;
    border-bottom: none;
    color: @table-head-color;

    &:first-child {
      border-top-left-radius: @border-radius-large;
    }
    &:last-child {
      border-top-right-radius: @border-radius-large;
    }
    .ico.ico-include-sold-shares {
      color: darken(@table-head-bg, 40%);
    }
  }

  > tfoot > tr > td,
  tr.footer-row > td {
    font-weight: 700;
    border-top: 3px double @table-border-color;
  }

  > thead > tr > th.valign-middle,
  > tbody > tr > td.valign-middle {
    vertical-align: middle;
  }
}

.table-hover {
  .table-hover();
  > tfoot {
    > tr.hover {
      > td,
      > th {
        background-color: @table-bg-hover;
      }
    }
  }
}

// .data-table for horizontal scrolling on small devices.
.data-table {
  min-width: 100%;

  table {
    // Ensure the content doesn't wrap
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th {
          white-space: normal;
        }
        > td {
          white-space: nowrap;
        }
      }
    }
  }

  @media print {
    overflow: hidden;
  }

  @media screen and (max-width: @screen-desktop) {
    margin-bottom: @gutter;
    overflow-y: hidden;
    overflow-x: auto; // this used to be strictly `scroll`, but auto will scroll when necessary..

    table {
      min-width: @screen-tablet - 33 !important;
    }
  }
}


table.spreadsheet,
table.table {
  &.clickable {
    &:extend(.table-hover all);
    tr td {
      cursor: pointer;
    }
  }
}

table.spreadsheet {
  &:extend(.table all);
}


/*
 * selected td styles
 */
table {
  // selected
  tr.selected {
    td:first-child {
      .box-shadow(-3px 0 0 0 @brand-success);
    }
    > td {
      background: @table-selected-bg;
    }
  }
  td.selected {
    background: @table-selected-bg;
  }

  &.clickable {
    tr.selected:hover > td {
      background: darken(@table-selected-bg, 5%) !important;
    }
    td.selected {
      background: @table-selected-bg;
    }
  }

  tr.suggested {
    td:first-child {
      .box-shadow(-3px 0 0 0 @brand-success);
    }
    > td {
      background: @table-suggested-bg;
    }
  }
  td.suggested {
    background: @table-suggested-bg;
  }

  &.clickable {
    tr.suggested:hover > td {
      background: darken(@table-suggested-bg, 5%) !important;
    }
    td.suggested {
      background: @table-suggested-bg;
    }
  }

  tr.unselected > td,
  td.unselected {
    background: @table-unselected-bg;
  }

  tr.unselected > td:first-child,
  td.unselected:first-child {
    .box-shadow(-3px 0 0 0 @brand-danger);
  }

  tr.unhandled > td,
  td.unhandled {
    background: @table-unhandled-bg;
  }

  tr.unhandled > td:first-child,
  td.unhandled:first-child {
    .box-shadow(-3px 0 0 0 @brand-secondary);
  }

  tr.deleted td,
  td.deleted {
    opacity: 0.5;
    .transition( all .25s ease-in-out);
  }

  tr.deleted td:first-child,
  td.deleted:first-child {
    .box-shadow(-3px 0 0 0 @gray-light);
  }
}

.no-wrap {
  white-space:nowrap!important;
}

.wrap-normal {
  white-space: normal!important;
}

.no-overflow {
  overflow: hidden;
  text-overflow: ellipsis; // makes overflow dotdotdot ...
}

.tr-lining-up td {
  padding: 0!important;
  height: 1px!important;
  font-size: 1px;
  font-size: 0.1px!important;
  overflow: hidden;
}

div.table_scrollable {
  @media screen {
    overflow-x: auto; // this used to be strictly `scroll`, but auto will scroll when necessary..
  }

  table {
    table-layout: auto;
    tbody tr td:first-child {
      max-width: 200px;
    }
  }
}

.volume-portfolios-table__middle-header {
  width: 430px;

  @media (max-width: @screen-tablet) {
    width: 40%;
  }
}

.volume-pending-clients-table__email-header {
  width: 480px;

  @media (max-width: @screen-tablet) {
    width: 33%;
  }
}

table.tax-return {
  table-layout: fixed;
  width: 100%;
}

td.tax-label {
  width: 70pc;
}

td.tax-section {
  width: 10pc;
}

td.tax-value {
  width: 20pc;
}
