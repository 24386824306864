#password-strength-indicator,
#password-confirmation-indicator {
  font-size: @font-size-xs;
  .password_strength {
    &.badPass,
    &.shortPass {
      color: @state-danger-text;
    }
    &.goodPass {
      color: @state-success-text;
    }
  }
}

