.horizontal-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  > * {
    margin-right: @gutter/2;
  }

  @media (max-width: @screen-phone) {
    > * {
      margin-right: 0!important;
    }
  }

  .field {
    margin-bottom: 0;
  }
}

.pull-right-flex {
  margin-left: auto;
  @media (max-width: @screen-phone) {
    margin-left: 0;
    > * {
      margin-left: 0!important;
    }
  }
}
